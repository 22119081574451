<div class="main-container">
  <div
    class="lazy-background"
    [ngStyle]="{ 'background-image': 'url(' + backgroundImageUrl + ')' }"
  ></div>
  <div class="lazy-background-opacity"></div>

  <div class="main-content">
    <div class="l max-w-4xl">
      <!-- <h1 class="selectDisable">
        <span>Goditi</span><br />
        l'esperienza al massimo
      </h1> -->

      <h1 class="title-type1">
        <div class="blink">
          <span id="type-Profes"></span>
        </div>

        <div class="mini" id="type-dedi">dedizione</div>
        <div class="blink">
          <span id="type-Divert"></span>
        </div>
      </h1>

      <!-- <button
        mat-stroked-button
        class="border-2 border-black"
        color="orange"
        (click)="register()"
        *ngIf="!authService.isLoggedIn()"
      >
        Entra in famiglia
      </button> -->
    </div>
    <div class="r" [@fadeInUp]>
      <img
        style="z-index: 0"
        ngSrc="assets/images/ForPages/bodyPilot.webp"
        alt=""
        width="300"
        height="900"
        class="selectDisable"
        priority
        draggable="false"
      />
    </div>
  </div>
</div>

<section class="bg-stock !m-0 relative overflow-y-hidden !z-0">
  <div class="animation-wrapper">
    <div class="particle particle-1"></div>
    <div class="particle particle-2"></div>
    <div class="particle particle-3"></div>
    <div class="particle particle-4"></div>
  </div>

  <h1 class="title-type2 mt-24">
    <div class="mini cursor-pointer hover-changeColor" (click)="register()">
      Entra in
    </div>
    <span>Famiglia</span>
  </h1>

  <!-- <div class="goUp">
    <mat-icon class="mat-18 text-white" (click)="scrollToTop()"
      >keyboard_arrow_up</mat-icon
    >
    <p class="text-white" (click)="scrollToTop()">Torna su</p>
  </div> -->

  <section class="pages-liner">
    <div class="title mb-16 mt-10 text-white text-4xl flex justify-center">
      <span>eventi </span><span> importanti</span>
    </div>
    <div class="accordion">
      <div
        class="accordion-img"
        *ngFor="let img of accordionImages"
        (click)="onImageClick(img)"
        [ngClass]="{ show_img: img.show }"
      >
        <img
          [ngSrc]="img.path"
          class="selectDisable"
          alt=""
          width="400"
          height="200"
          loading="lazy"
        />
        <div class="box">
          <h2>{{ img.title }}</h2>
          <div class="other" *ngIf="img.click != ''">
            <button mat-raised-button color="orange" [routerLink]="[img.click]">
              Vai all'evento
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="bi-box">
    <div class="big-box w-2/5">
      <h2 class="title">Chi siamo</h2>
      <p class="text">
        Il drift è la nostra passione, e grazie a tutto lo staff siamo riusciti
        a portarvelo anche a voi. Vogliamo portare sempre più gente a capire
        cosa effettivamente Tazio Nuvolari faceva durante le sue gare, e da come
        questa disciplina sia esplosa dopo il magnifico Tsuchiya e dal famoso
        film di Tokyo Drift
      </p>
    </div>
    <div class="big-box">
      <div class="grid grid-cols-3 max-md:!grid-cols-2">
        <div class="box">
          <mat-icon> stay_current_portrait</mat-icon>
          <h2>Smart</h2>
          <p>
            iscrizioni, eventi, registrazioni, media <br />
            tutto in questo sito
          </p>
        </div>
        <div class="box">
          <mat-icon class="text-red-600"> show_chart</mat-icon>
          <h2>In espansione</h2>
          <p>
            con tantissimi ragazzi e ragazze che ogni giorno entrano a far parte
            di questa grande famiglia
          </p>
        </div>
        <div class="box">
          <mat-icon class="text-sky-300">subscriptions</mat-icon>
          <h2>Social</h2>
          <p>siamo presenti su tutti i social "famosi"</p>
        </div>
        <div class="box">
          <mat-icon class="text-amber-900">people</mat-icon>
          <h2>Team</h2>
          <p>Un team affiatato e sempre pronto ad aiutarvi</p>
        </div>
        <div class="box">
          <mat-icon class="text-lime-600"> monetization_on</mat-icon>
          <h2>Prezzi</h2>
          <p>Prezzi competitivi come non avete mai visto prima</p>
        </div>
        <div class="box">
          <mat-icon class="text-purple-700"> local_gas_station</mat-icon>
          <h2>A tutto gas</h2>
          <p>E come si dice, Sempre a fondo!!!</p>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="PersonalCar">
    <h1 class="yellow-title selectDisable text-center">Le vostre auto</h1>
    <h2>
      Visto la vostra carissima partecipazione <br />
      vi offriamo un regalo!
    </h2>
    <p>
      Chiunque abbia un account attivo potrà inserire la propria auto nel vostro
      profilo,<br />
      ad estrazione potreste venire contattati per poter inserire tutte le
      vostre foto nella nostra galleria!
    </p>
    <h4 hidden>
      Attenzione: <br />
      Le foto vengono confermate dallo staff prima di essere in
      visualizzazione,<br />
      quindi attenzione a cosa si carica
    </h4>
    <div
      class="img-slider"
      *ngIf="publicImgLists != null && publicImgLists.length > 0"
    >
      <div class="arrow left" (click)="prevImgSlide()">&#10094;</div>
      <div
        class="slides"
        [style.transform]="'translateX(' + (-currentImgIndex * 100) / 3 + '%)'"
      >
        <div
          class="slide"
          *ngFor="let img of publicImgLists; let i = index"
          [class.active]="i === currentImgIndex"
        >
          <img
            class="selectDisable"
            [src]="apiUrl + 'images/Car/' + img.fileName"
            alt="immagine non caricata"
          />
        </div>
      </div>
      <div class="arrow right" (click)="nextImgSlide()">&#10095;</div>
    </div>
  </section> -->

  <section class="Abbonamenti pt-24">
    <h2 class="title">Affiliati</h2>
    <div class="selector">
      <div class="select mover text-white">
        <div class="dot bg-pink-500"></div>
        <h3>
          <span class="active">1 Gen - 31 Dic</span>
        </h3>
        <h1>Tessera team</h1>
        <h2>15€</h2>
        <ul>
          <li>
            <mat-icon class="!text-green-500">check</mat-icon>Tessera SMDT
          </li>
          <li><mat-icon>close</mat-icon>Tessera FAMS</li>
          <li>
            <mat-icon class="!text-green-500">check</mat-icon>Prenotazione
            eventi Statici
          </li>
          <li>
            <mat-icon class="!text-orange-500">remove</mat-icon>Prenotazione
            eventi Dinamici
          </li>
          <li><mat-icon class="!text-green-500">check</mat-icon>Taxi drift</li>
        </ul>
      </div>

      <div class="select mover text-white">
        <div class="dot bg-red-600"></div>
        <h3>
          <span class="active"
            >1 Gen - 31 Dic</span
          >
        </h3>
        <h1>Full tessere</h1>
        <h2>40€</h2>
        <ul>
          <li>
            <mat-icon class="!text-green-500">check</mat-icon>Tessera SMDT
          </li>
          <li>
            <mat-icon class="!text-green-500">check</mat-icon>Tessera FAMS
          </li>
          <li>
            <mat-icon class="!text-green-500">check</mat-icon>Prenotazione
            eventi Statici
          </li>
          <li>
            <mat-icon class="!text-green-500">check</mat-icon>Prenotazione
            eventi Dinamici
          </li>
          <li><mat-icon class="!text-green-500">check</mat-icon>Taxi drift</li>
        </ul>
      </div>

      <div class="select mover text-white">
        <div class="dot bg-teal-600"></div>
        <h3><span class="active">1 Gen - 31 Dic</span></h3>
        <h1 class="warn">Professionista</h1>
        <h2>500€</h2>
        <ul>
          <li>
            <mat-icon class="!text-green-500">check</mat-icon>Tessera SMDT
          </li>
          <li>
            <mat-icon class="!text-green-500">check</mat-icon>Licenza
            competitiva FAMS
          </li>
          <li>
            <mat-icon class="!text-green-500">check</mat-icon>Prenotazione
            eventi Statici
          </li>
          <li>
            <mat-icon class="!text-green-500">check</mat-icon>Prenotazione
            eventi Dinamici
          </li>
          <li>
            <mat-icon class="!text-green-500">check</mat-icon>Accesso competizioni internazionali
          </li>
        </ul>
      </div>

      <div class="select mover text-white">
        <h3><span class="active">Singolo</span></h3>
        <div class="dot bg-blue-600"></div>
        <h1 class="warn">Taxi drift</h1>
        <h2>10€</h2>
        <ul>
          <li><mat-icon class="!text-green-500">check</mat-icon>Taxi drift</li>
        </ul>
      </div>

      <!-- <div class="select mover text-white max-md:!h-72">
        <h3><span class="active">Singolo</span></h3>
        <div class="dot bg-green-500"></div>
        <h1 class="warn">Media</h1>
        <h2>20€</h2>
        <ul>
          <li><mat-icon class="!text-green-500">check</mat-icon>Media</li>
        </ul>
        <button
          mat-raised-button
          color="orange"
          class="!font-bold !uppercase !rounded-2xl"
          (click)="customNavigateTo('abbonamenti', $event)"
        >
          Info
        </button>
      </div> -->
    </div>
  </section>

  <section class="contattaci">
    <app-contattaci></app-contattaci>
  </section>

  <app-footer class="relative z-0" />
</section>
