<div class="contact_main text-white">
  <h1 class="title text-center"><span>Qualche</span> dubbio?</h1>
  <p class="text-3xl">contattaci</p>
  <div class="form_container">
    <div class="inputs">
      <form [formGroup]="form">
        <mat-form-field appearance="fill" class="w-full text-black">
          <mat-label>email</mat-label>
          <input matInput placeholder="la tua email" formControlName="email" />
          <mat-icon matPrefix>alternate_email</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-full text-black">
          <mat-label
            >descrizione (minimo 20 caratteri)</mat-label
          >
          <textarea
            matInput
            rows="3"
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="4"
            #descriptionInput
            maxlength="250"
            placeholder="spiegazione del problema riscontrato o una domanda relativa al team"
            formControlName="descrizione"
            value=""
          ></textarea>
          <mat-icon matPrefix>description</mat-icon>
        </mat-form-field>
      </form>
    </div>

    <div class="buttons my-8 w-4/5 scale-125 text-white">
      <button
        mat-raised-button
        color="warn"
        [disabled]="!form.valid"
        (click)="submit()"
      >
        Invia
      </button>
    </div>
  </div>
</div>
