import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CommonService } from '../../services/common.service';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CounterCharactersPipe } from '../../pipe/counter-characters.pipe';

@Component({
    selector: 'app-contattaci',
    imports: [
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
        CounterCharactersPipe,
    ],
    templateUrl: './contattaci.component.html',
    styleUrl: './contattaci.component.scss'
})
export class ContattaciComponent implements OnInit {
  @Output() childEmitter: EventEmitter<any> = new EventEmitter<any>();

  form!: FormGroup;
  select!: '';
  commonService = inject(CommonService);
  matSnackBar = inject(MatSnackBar);

  constructor(
    private fb: FormBuilder,
    private ref: MatDialogRef<ContattaciComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      descrizione: ['', Validators.required],
    });
  }

  submit() {
    if (this.form.valid) {
      this.commonService
        .SaveContactsForm(this.form.value)
        .subscribe((response) => {
          if (response != null)
            this.childEmitter.emit({ close: true, disable: true });

          this.matSnackBar.open(response.message, 'Chiudi', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        });
    }
  }
}
