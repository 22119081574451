import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  inject,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ExtraOptions, RouterLink } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import {
  MatDialog,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { RegisterComponent } from '../../components/register/register.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { FooterComponent } from '../../components/footer/footer.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { ContattaciComponent } from '../../components/contattaci/contattaci.component';
import { CommonService } from '../../services/common.service';
import { CommonHomeObj } from '../../interfaces/common-home-obj';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { timer } from 'rxjs';
import { OpenPopup } from '../../function/open-popup';
import { PublicModulesFunction } from '../../function/public-modules-function';
import { environment } from '../../../environments/environment';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

@Component({
    selector: 'app-home',
    providers: [MatDatepickerModule, { provide: MatDialogRef, useValue: {} }],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
    // changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('slideIn', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateY(-100%)',
                }),
                animate(400),
            ]),
        ]),
        trigger('fadeInUp', [
            state('void', style({
                opacity: 0,
                transform: 'translateY(10rem)',
            })),
            transition(':enter', [
                animate('2s ease-out', style({
                    opacity: 1,
                    transform: 'translateY(0)',
                })),
            ]),
        ]),
        trigger('float', [
            state('void', style({ opacity: 1, transform: 'translateY(0)' })),
            transition('void => start', [
                style({ opacity: 0, transform: 'translateY(50px)' }),
                animate('1s ease-out'),
            ]),
        ]),
    ],
    imports: [
        ...PublicModulesFunction,
        RouterLink,
        MatDialogModule,
        FooterComponent,
        MatIconModule,
        MatExpansionModule,
        MatButtonModule,
        ContattaciComponent,
    ]
})
export class HomeComponent implements OnInit, OnDestroy {
  backgroundImageUrl = '../../assets/images/ForPages/SmDriftBackground_80.webp';
  observer!: IntersectionObserver;
  private text1: string = 'Professionalità';
  private typingSpan1: HTMLElement | null = null;

  private text2: string = 'Divertimento';
  private typingSpan2: HTMLElement | null = null;

  imagePath = 'assets/images/';
  accordionImages = [
    {
      id: 1,
      show: false,
      path: this.imagePath + 'buntas/screen-serravalle-2.webp',
      click: '/buntas',
      title: "Bunta's",
    },
    {
      id: 2,
      show: true,
      path: this.imagePath + 'ForPages/driftDay.webp',
      click: '',
      title: 'Drift day',
    },
    {
      id: 3,
      show: false,
      path: this.imagePath + 'ForPages/whiteMeeting.webp',
      click: '',
      title: 'White meeting',
    },
    {
      id: 4,
      show: false,
      path: this.imagePath + 'ForPages/nissanS15.webp',
      click: '',
      title: 'Altro in arrivo!',
    },
  ];

  staticAttive: boolean = false;
  dynamicAttive: boolean = false;

  isMobile: boolean = false;

  currentImgIndex = 0;
  publicImgLists: any;
  private intervalId: any;

  openContact: boolean = false;
  disableContact: boolean = false;
  ObjectContainer?: CommonHomeObj;
  commonService = inject(CommonService);
  authService = inject(AuthService);
  apiUrl = environment.apiUrl;

  timelineItems!: NodeListOf<Element>;

  constructor(
    private dialog: MatDialog,
    private injector: Injector,
    private router: Router,
    private renderer: Renderer2,
    private ref: ElementRef
  ) {}

  ngOnInit(): void {
    if (typeof window !== 'undefined') {
      const observerCallback = (
        entries: IntersectionObserverEntry[],
        observer: IntersectionObserver
      ) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.classList.contains('lazy-background')) {
              entry.target.classList.add('loadedBg');
              observer.unobserve(entry.target);
            } else {
              entry.target.classList.add('show');
            }
          }
        });
      };

      // const getCarImages = this.authService.getAllCarImages();
      const options = { root: null, rootMargin: '0px', threshold: 0.1 };
      const observer = new IntersectionObserver(observerCallback, options);

      //get all public images of car and people
      // getCarImages.subscribe((res) => {
      //   this.publicImgLists = res;
      // });

      this.startAutoSlide();

      //* Lazy background image
      const bgImageElement = document.querySelector('.lazy-background');
      if (bgImageElement) {
        observer.observe(bgImageElement);
      }

      this.CheckDeviceType();

      //* Write text on start
      this.typingSpan1 = document.getElementById('type-Profes');
      this.typingSpan2 = document.getElementById('type-Divert');

      this.type(this.typingSpan1, this.text1, 0, () => {
        document.getElementById('type-dedi')?.classList.add('show');

        setTimeout(() => {
          this.type(this.typingSpan2, this.text2, 0);
        }, 1000);
      });

      //* Mover and fader elements
      const elementsToObserve = document.querySelectorAll('.mover, .fader');
      elementsToObserve.forEach((el) => observer.observe(el));
    }
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  CheckDeviceType() {
    if (typeof window !== 'undefined') this.isMobile = window.innerWidth <= 858;
  }

  onImageClick(clickedImage: any) {
    this.accordionImages.forEach((img) => (img.show = false));
    clickedImage.show = true;
  }

  startAutoSlide() {
    this.intervalId = setInterval(() => {
      this.nextImgSlide();
    }, 5000);
  }

  nextImgSlide() {
    this.currentImgIndex =
      (this.currentImgIndex + 1) % this.publicImgLists.length;
  }

  prevImgSlide() {
    this.currentImgIndex =
      (this.currentImgIndex - 1 + this.publicImgLists.length) %
      this.publicImgLists.length;
  }

  private type(
    span: HTMLElement | null,
    text: string,
    index: number,
    callback?: () => void
  ): void {
    if (span && index < text.length) {
      span.innerHTML += text.charAt(index);
      setTimeout(() => {
        this.type(span, text, index + 1, callback);
      }, 80);
    } else if (callback) callback();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const boxItem = document.querySelectorAll('.boxTimer');
    const scrollPosition = window.scrollY;

    boxItem.forEach((item, index) => {
      const delay = index * 50;
      const offsetTop = item.getBoundingClientRect().top + window.scrollY;
      const initialOffset = 700;
      const offset = Math.min(
        scrollPosition - offsetTop + delay + initialOffset,
        0
      );

      this.renderer.setStyle(item, 'transform', `translateX(${offset}px)`);
    });
  }

  register() {
    setTimeout(() => {
      OpenPopup(
        this.dialog,
        this.injector,
        null,
        'Registrati',
        RegisterComponent,
        '100%',
        true
      );
    }, 400);
  }

  goToTelegram() {
    window.open('https://t.me/+Mdc_tYEc9dE0Zjk0', '_blank');
  }

  registerDelay() {
    this.createDelayUsingTimer(1000).subscribe((res) => {
      OpenPopup(
        this.dialog,
        this.injector,
        null,
        'Aggiungi Evento',
        RegisterComponent,
        null,
        true
      );
    });
  }

  createDelayUsingTimer(delayValue: number) {
    return timer(delayValue);
  }

  getCloseFromContact(e: any) {
    this.openContact = !e.close;
    this.disableContact = e.disable;
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  customNavigateTo(Page: string, event: MouseEvent) {
    if (event.button === 1) {
      window.open(Page, '_blank');
    } else if (event.button === 0)
      this.router.navigate([Page]);

      this.scrollToTop();
  }
}
